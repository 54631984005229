const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  PERSONAL_DATA: 'PERSONAL_DATA',
  DOC_DATA: 'DOC_DATA',
  BANK_DATA: 'BANK_DATA',
  UBER_DATA: 'UBER_DATA',
  ADMIN_DATA: 'ADMIN_DATA',
  DRIVER_TEST_LISTING: 'DRIVER_TEST_LISTING',
  DRIVER_TRAINING_LISTING: 'DRIVER_TRAINING_LISTING',
  DRIVER_DEPOSIT_LIST: 'DRIVER_DEPOSIT_LIST',
  SAVE_INCOMPLETE_DRIVER_DETAIL: 'SAVE_INCOMPLETE_DRIVER_DETAIL',
  SAVE_DRIVER_CITY: 'SAVE_DRIVER_CITY',
  DRIVER_DEFAULT_CITY: 'DRIVER_DEFAULT_CITY',

  SAVE_NEW_DRIVER_DETAIL: 'SAVE_NEW_DRIVER_DETAIL',
  ONBOARDING_STATUS: 'ONBOARDING_STATUS',
  ONBOARDING: 'ONBOARDING',
  SAVE_DRIVER_ID: 'SAVE_DRIVER_ID',
  SAVE_VERIFY_MANUALLY: 'SAVE_VERIFY_MANUALLY',
  MILESTONE_STATUS_LIST: 'MILESTONE_STATUS_LIST',
  SET_IS_DASHBOARD_LOCKED: 'SET_IS_DASHBOARD_LOCKED',
  SET_USER_ONBOARDING_ROLE: 'SET_USER_ONBOARDING_ROLE',
};

export default actions;
