export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
