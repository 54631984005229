import {MenuOutlined} from '@ant-design/icons';
import {Button, Drawer, Popover, Select} from 'antd';
import Cookies from 'js-cookie';
import {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {store} from 'redux/store';
import actions from 'redux/user/actionTypes';
import {userLoad, userLogout} from 'redux/user/actions';

import {useTeamContext} from 'context/teamIDContext';

import useWindowSize from 'hooks/useResize';

import {useLocalStorage} from 'context/onboardStepsContext';
import WeekCalender from '../AppHeader/weekCalender';
import Sidebar from '../sidebar';
import DayCalender from './dayCalender';

import {garageDefaultRouteBasedOnRole, garageDefaultRouteBasedOnRoleUrl} from 'layout';
import {DashboardURI, LogoutActions} from 'utility/dashboardEnum';
import {useQuery} from 'utility/useQuery';
import {getTeamName} from '../AppHeader';

const Header = ({logout, loadCurrentAccount, userRole}) => {
  const history = useHistory();
  const windowSize = useWindowSize();

  const {userReducer, garageReducer} = store.getState();
  const {setDataInLocalStorage} = useLocalStorage();
  const {
    name,
    team,
    teamName: auditTeamName,
    partner,
    loc,
    manager,
    city,
    cityName,
    management,
    permissions,
    dashboard,
  } = userReducer;
  const {teamName: garageTeamName} = garageReducer;

  const {setTeamID, setPartnerID, setLocID, setManagerID, setCityID, setSelectedDashboard} = useTeamContext();
  const searchParams = useQuery();
  const dashboardName = searchParams.get('dashboard_name');

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [dashboardValue, setDashboardValue] = useState(dashboard);
  const [loading, setLoading] = useState(false);

  const teamName = useMemo(
    () => getTeamName(dashboardValue, {audit: auditTeamName, garage: garageTeamName, default: auditTeamName}),
    [dashboardValue, auditTeamName, garageTeamName]
  );

  useEffect(() => {
    setTeamID({teamIDValue: Number(team)});
    setPartnerID({partnerIDValue: Number(partner)});
    setLocID({locIDValue: Number(loc)});
    setManagerID({managerIDValue: Number(manager)});
    setCityID({cityIDValue: Number(city)});
  }, [team, partner, loc, manager, city]);

  useEffect(() => {
    if (dashboardName) {
      setSelectedDashboard(dashboardName);
      setDashboardValue(dashboardName);
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard: dashboardName,
        },
      });
      loadCurrentAccount(); // load currentAccount on every dashboard change
      Cookies.set('dashboard', dashboardName);
    }
  }, [dashboardName]);

  const onSelectDashboard = dashboard => {
    setDashboardValue(dashboard);
    setSelectedDashboard(dashboardName);
    if (dashboardValue !== dashboard) {
      store.dispatch({
        type: actions.SET_STATE,
        payload: {
          dashboard,
        },
      });
      loadCurrentAccount(); // load currentAccount on every dashboard change
      Cookies.set('dashboard', dashboard);
      const dashboardParam = `dashboard_name=${dashboard}`;
      if (permissions[dashboard]?.uri === DashboardURI.AUDITDASHBOARD) {
        history.push(`/home/audit/checkIn/checkIn?${dashboardParam}`);
      } else if (permissions[dashboard]?.uri === DashboardURI.RECOVERYDASHBOARD) {
        history.push(`/home/recoveryDash?${dashboardParam}`);
      } else if (permissions[dashboard]?.uri === DashboardURI.ONBOARDDRIVER) {
        history.push(`/home/onboardDriver?${dashboardParam}`);
      } else if (permissions[dashboard]?.uri === DashboardURI.JARVIS) {
        history.push('/home/paymentReport');
      } else if (permissions[dashboard]?.uri === DashboardURI.GARAGEDASHBOARD) {
        garageDefaultRouteBasedOnRole(history, userRole?.uri, dashboard);
      } else if (permissions[dashboard]?.uri === DashboardURI.TASKTRACKER) {
        history.push(`/home/taskTracker/taskDefList?${dashboardParam}`);
      } else {
        history.push(`/home/dashboard?${dashboardParam}`);
      }
    }
  };

  const getUrl = item => {
    const dashboardParam = `dashboard_name=${item}`;
    if (permissions[item]?.uri === DashboardURI.AUDITDASHBOARD) {
      return `/#/home/audit/checkIn/checkIn?${dashboardParam}`;
    } else if (permissions[item]?.uri === DashboardURI.RECOVERYDASHBOARD) {
      return `/#/home/recoveryDash?${dashboardParam}`;
    } else if (permissions[item]?.uri === DashboardURI.ONBOARDDRIVER) {
      return `/#/home/onboardDriver?${dashboardParam}`;
    } else if (permissions[item]?.uri === DashboardURI.JARVIS) {
      return `/#/home/paymentReport?${dashboardParam}`;
    } else if (permissions[item]?.uri === DashboardURI.GARAGEDASHBOARD) {
      return garageDefaultRouteBasedOnRoleUrl(userRole?.uri, item);
    } else if (permissions[item]?.uri === DashboardURI.TASKTRACKER) {
      return `/#/home/taskTracker/taskDefList?${dashboardParam}`;
    } else {
      return `/#/home/dashboard?${dashboardParam}`;
    }
  };
  const handleSignOut = () => {
    setDataInLocalStorage({index: 0});
    setLoading(true);
    logout(LogoutActions.SIGNOUT);
  };

  const getBgColor = item => {
    if (item === dashboardValue) return '#E6F7FF';
    return '#FFFFFF';
  };

  const showSidebar = () => {
    setSidebarOpen(true);
  };

  const onCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const showProfile = () => {
    setProfileOpen(true);
  };
  const onCloseProfile = () => {
    setProfileOpen(false);
  };

  let laptop;
  if (windowSize.width < 992) {
    laptop = true;
  } else {
    laptop = false;
  }

  let tablet;
  if (windowSize.width < 768) {
    tablet = true;
  } else {
    tablet = false;
  }

  return (
    <div className="p-4 pb-2 bg-[#013453]">
      <div className="flex justify-between items-center py-2.5">
        <div className="flex items-center sm:gap-8 gap-4">
          {laptop ? (
            <>
              <Button type="primary" icon={<MenuOutlined />} onClick={showSidebar} size="large" />
              <Drawer
                title={dashboardValue?.split(' ')[0]}
                placement="left"
                open={isSidebarOpen}
                onClose={onCloseSidebar}
                width={250}
                bodyStyle={{
                  padding: 0,
                }}
              >
                <Sidebar />
              </Drawer>
            </>
          ) : null}
          <div className="flex flex-col justify-center">
            <p className="text-[#FFFFFF] font-extrabold text-xl md:text-2xl m-0">
              <span className="hidden sm:inline">Welcome</span> {name}
            </p>
            {manager?.length ? (
              <div className="flex gap-2 md:mt-2.5 text-xs font-regular text-[#86A1A4]">
                <p className="mb-1 md:mb-2 md:pr-2.5 md:border-solid md:border-r-2 md:border-[#86A1A4]">
                  <span className="hidden md:inline-block">Designation:</span>{' '}
                  <span className="text-[#FFFFFF]">Driver Manager</span>
                </p>
                <p className="mb-1 md:mb-2">
                  <span className="hidden md:inline-block">Branch: </span>{' '}
                  <span className="text-[#FFFFFF]">{cityName}</span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {tablet ? (
          <>
            <div className="flex items-center gap-4">
              <Button
                type="primary"
                icon={<img src="/assets/images/general/man.svg" alt="user" />}
                onClick={showProfile}
                size="large"
                className="p-0 bg-transparent border-0 focus:border-0"
              />
            </div>
            <Drawer
              title={<p className="font-medium text-xl m-0 pt-1">{name}</p>}
              placement="right"
              open={isProfileOpen}
              onClose={onCloseProfile}
              width={250}
              bodyStyle={{padding: '2rem 2.5rem'}}
            >
              <div className="flex flex-col">
                {Object.keys(permissions).map(item => {
                  return (
                    <Button
                      key={item}
                      onClick={() => onSelectDashboard(item)}
                      type="button"
                      className="mb-1"
                      style={{backgroundColor: getBgColor(item)}}
                    >
                      <a href={getUrl(item)} target="_top" className="text-[#000000]">
                        {item}
                      </a>
                    </Button>
                  );
                })}
                <hr className="my-2" />
                <Button onClick={handleSignOut} type="primary" loading={loading}>
                  Sign Out
                </Button>
              </div>
            </Drawer>
          </>
        ) : (
          <div className="flex gap-4 md:flex-row flex-col-reverse">
            {management ? <DayCalender /> : <WeekCalender />}
            <Popover
              placement="bottomRight"
              title={name}
              content={
                <Button onClick={handleSignOut} className="w-36" type="primary" loading={loading}>
                  Sign Out
                </Button>
              }
              trigger="click"
            >
              <img src="/assets/images/general/man.svg" alt="user" width="40" className="cursor-pointer" />
            </Popover>
          </div>
        )}
      </div>
      <div>
        <hr className="text-[#86A1A4] my-0" />
        <div className="flex justify-between items-center my-2">
          <div className="flex items-center">
            <p className="text-[#37BDC8] font-semibold text-sm mb-0 ml-4">{teamName}</p>
          </div>
          {tablet ? (
            <div className="ml-auto">{management ? <DayCalender /> : <WeekCalender />}</div>
          ) : (
            <Select
              value={{
                value: dashboardValue,
                label: dashboardValue,
              }} // Removing anchor tag in value to handle redirection issue
              onChange={onSelectDashboard}
              options={Object.keys(permissions)?.map(item => ({
                value: item,
                label: (
                  <a href={getUrl(item)} target="_top" className="text-[#000000]">
                    {item}
                  </a>
                ),
              }))}
              style={{
                width: '11rem',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: action => dispatch(userLogout(action)),
  loadCurrentAccount: () => dispatch(userLoad()),
});
const mapStateToProps = ({userReducer, garageReducer}) => ({
  team: userReducer.team,
  userRole: garageReducer.userRole,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
