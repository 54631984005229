import Cookies from 'js-cookie';
import actions from './actionTypes';

const initialState = {
  loading: false,
  error: false,
  authorized: false,
  name: Cookies.get('name'),
  username: Cookies.get('username'),
  userId: Cookies.get('userId'),
  mobileNo: Cookies.get('mobileNo'),
  token: Cookies.get('token'),
  team: Cookies.get('team'),
  teamName: Cookies.get('teamName'),
  manager: Cookies.get('manager'),
  managerName: Cookies.get('managerName'),
  city: Cookies.get('city'),
  cityName: Cookies.get('cityName'),
  loc: Cookies.get('loc'),
  location: Cookies.get('location'),
  partner: Cookies.get('partner'),
  revenue: Cookies.get('revenue'),
  businessVerticalId: Cookies.get('businessVerticalId'),
  paymentModelId: Cookies.get('paymentModelId'),
  dashboard: Cookies.get('dashboard'),
  permissions: JSON.parse(localStorage.getItem('permissions')),
  companyAddress: Cookies.get('companyAddress'),
  companyName: Cookies.get('companyName'),
  isMaster: Cookies.get('isMaster'),
  personal_data: {},
  doc_data: {},
  bank_data: {},
  uber_data: {},
  admin_data: {},
  driver_TestListing_Data: {},
  driver_Training_Data: {},
  driver_deposit_data: {},
  incomplete_driver_details: {step: null, employeeId: null},
  driver_city: 1,
  default_city: 1,
  driverInfo: {},
  onboarding_status: '',
  onboarding: {},
  driver_id: {},
  verify_manually: {
    panManual: false,
    aadharManual: false,
    licenseManual: false,
  },
  milestoneStatusList: {},
  complexId: Cookies.get('complexId'),
  isPerfDashboardLocked: false,
  userOnboardingRole: null,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case actions.SET_STATE:
      return {...state, ...payload};
    case actions.PERSONAL_DATA:
      return {
        ...state,
        personal_data: action.payload,
      };
    case actions.DOC_DATA:
      return {
        ...state,
        doc_data: action.payload,
      };
    case actions.BANK_DATA:
      return {
        ...state,
        bank_data: action.payload,
      };
    case actions.UBER_DATA:
      return {
        ...state,
        uber_data: action.payload,
      };
    case actions.ADMIN_DATA:
      return {
        ...state,
        admin_data: action.payload,
      };
    case actions.DRIVER_TEST_LISTING:
      return {
        ...state,
        driver_TestListing_Data: action.payload,
      };
    case actions.DRIVER_TRAINING_LISTING:
      return {
        ...state,
        driver_Training_Data: action.payload,
      };
    case actions.DRIVER_DEPOSIT_LIST:
      return {
        ...state,
        driver_deposit_data: action.payload,
      };
    case actions.SAVE_INCOMPLETE_DRIVER_DETAIL:
      return {
        ...state,
        incomplete_driver_details: action.payload,
      };
    case actions.SAVE_DRIVER_CITY:
      return {
        ...state,
        driver_city: action.payload,
      };
    case actions.DRIVER_DEFAULT_CITY:
      return {
        ...state,
        default_city: action.payload,
      };
    case actions.SAVE_NEW_DRIVER_DETAIL:
      return {
        ...state,
        driverInfo: action.payload,
      };
    case actions.ONBOARDING_STATUS:
      return {
        ...state,
        onboarding_status: action.payload,
      };
    case actions.ONBOARDING:
      return {
        ...state,
        onboarding: action.payload,
      };
    case actions.SAVE_DRIVER_ID:
      return {
        ...state,
        driver_id: action.payload,
      };
    case actions.SAVE_VERIFY_MANUALLY:
      return {
        ...state,
        verify_manually: action.payload,
      };
    case actions.MILESTONE_STATUS_LIST:
      return {
        ...state,
        milestoneStatusList: action.payload,
      };
    case actions.SET_IS_DASHBOARD_LOCKED:
      return {
        ...state,
        isPerfDashboardLocked: action.payload,
      };
    case actions.SET_USER_ONBOARDING_ROLE:
      return {
        ...state,
        userOnboardingRole: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
